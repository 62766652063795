import React, { useRef, useState } from "react"

import { motion } from "framer-motion"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import PropTypes from "prop-types"
import { NotificationContainer } from "react-notifications"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import EditableDisplay from "components/Forms/EditableDisplay"
import EditableDisplayWithReset from "components/Forms/EditableDisplayWithReset"
import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import AddTailoredAssignment from "./AddTailoredAssignment"
import Availabilities from "./Availabilities"
import CourseDetails from "./CourseDetails"
import CreateStudyGroupTutorReplacement from "./CreateStudyGroupTutorReplacement"
import DeleteFutureSessions from "./DeleteFutureSessions"
import GenerationDetails from "./GenerationDetails"
import SessionDetails from "./SessionDetails"
import Students from "./Students"
import Subjects from "./Subjects"
import StudyGroupTags from "./Tags"
import Teachers from "./Teachers"
import Tutor from "./Tutor"

import "react-notifications/lib/notifications.css"

const StudyGroupDetails = ({ id, scheduleSessionPath }) => {
  const planSelectRef = useRef()
  const [page, setPage] = useState(1)
  const [studentErrorMessages, setStudentErrorMessages] = useState()
  const loadingState = useQuery(
    buildQuery(
      studyGroupQuery,
      { id, page },
      {
        onMutation: [
          {
            when: "studyGroupAddUser",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, studyGroup } = response.studyGroupAddUser
              if (errorMessages) {
                setStudentErrorMessages(errorMessages)
                return
              } else {
                setStudentErrorMessages()
              }

              if (studyGroup.students) {
                currentResults.studyGroup.students = studyGroup.students
              }
              if (studyGroup.teachers) {
                currentResults.studyGroup.teachers = studyGroup.teachers
              }
              if (studyGroup.tutor) {
                currentResults.studyGroup.tutor = studyGroup.tutor
              }
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupRemoveUser",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupRemoveUser
              if (studyGroup.students) {
                currentResults.studyGroup.students = studyGroup.students
              }
              if (studyGroup.teachers) {
                currentResults.studyGroup.teachers = studyGroup.teachers
              }
              if (Object.keys(studyGroup).includes("tutor")) {
                currentResults.studyGroup.tutor = studyGroup.tutor
              }
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupAddSubject",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupAddSubject
              currentResults.studyGroup.subjects = studyGroup.subjects
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupRemoveSubject",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupRemoveSubject
              currentResults.studyGroup.subjects = studyGroup.subjects
              softReset(currentResults)
            },
          },
          {
            when: "removeStudyGroupTutorReplacement",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.removeStudyGroupTutorReplacement
              currentResults.studyGroup.futureStudyGroupTutorReplacements =
                studyGroup.futureStudyGroupTutorReplacements
              currentResults.studyGroup.openStudyGroupTutorReplacements =
                studyGroup.openStudyGroupTutorReplacements
              softReset(currentResults)
            },
          },
          {
            when: "assignLessonPlan",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.assignLessonPlan
              currentResults.studyGroup.lessonPlans = studyGroup.lessonPlans
              softReset(currentResults)
            },
          },
          {
            when: "airtutorsSessionAddStudent",
            run: ({ softReset, currentResults }, response) => {
              const { airtutorsSession, errors } =
                response.airtutorsSessionAddStudent
              if (errors) return
              currentResults.studyGroup.airtutorsSessions.data.forEach(
                currentSession => {
                  if (currentSession.id === airtutorsSession.id) {
                    currentSession.students = airtutorsSession.students
                  }
                }
              )
              softReset(currentResults)
            },
          },
          {
            when: "airtutorsSessionRemoveStudent",
            run: ({ softReset, currentResults }, response) => {
              const { airtutorsSession } =
                response.airtutorsSessionRemoveStudent
              currentResults.studyGroup.airtutorsSessions.data.forEach(
                currentSession => {
                  if (currentSession.id === airtutorsSession.id) {
                    currentSession.students = airtutorsSession.students
                  }
                }
              )
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupUpdate
              currentResults.studyGroup.name = studyGroup.name
              currentResults.studyGroup.active = studyGroup.active
              currentResults.studyGroup.minimumStudentsThreshold =
                studyGroup.minimumStudentsThreshold
              currentResults.studyGroup.details = studyGroup.details
              currentResults.studyGroup.formattedDetails =
                studyGroup.formattedDetails
              currentResults.studyGroup.formattedHourlyRate =
                studyGroup.formattedHourlyRate
              currentResults.studyGroup.hourlyRate = studyGroup.hourlyRate
              currentResults.studyGroup.formattedNoShowRate =
                studyGroup.formattedNoShowRate
              currentResults.studyGroup.chimeCloudroomEnabled =
                studyGroup.chimeCloudroomEnabled
              currentResults.studyGroup.lowBandwidthCloudroom =
                studyGroup.lowBandwidthCloudroom
              currentResults.studyGroup.allowLongSessions =
                studyGroup.allowLongSessions
              currentResults.studyGroup.allowLongSessionsSource =
                studyGroup.allowLongSessionsSource
              currentResults.studyGroup.organization = studyGroup.organization
              currentResults.studyGroup.school = studyGroup.school
              currentResults.studyGroup.foreignLanguage =
                studyGroup.foreignLanguage
              currentResults.studyGroup.minTutorConsistencyRate =
                studyGroup.minTutorConsistencyRate
              currentResults.studyGroup.excusedAbsencesEnabled =
                studyGroup.excusedAbsencesEnabled
              currentResults.studyGroup.requestConfirmations =
                studyGroup.requestConfirmations
              currentResults.studyGroup.canExceedScheduledDuration =
                studyGroup.canExceedScheduledDuration
              currentResults.studyGroup.inSchool = studyGroup.inSchool
              currentResults.studyGroup.budget = studyGroup.budget
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupAddAvailability",
            run: ({ softReset, currentResults }, response) => {
              const { availability } = response.studyGroupAddAvailability
              if (!availability) return
              currentResults.studyGroup.availabilities.push(availability)
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupRemoveAvailability",
            run: ({ softReset, currentResults }, response) => {
              const { availability } = response.studyGroupRemoveAvailability
              currentResults.studyGroup.availabilities =
                currentResults.studyGroup.availabilities.filter(
                  av => av.id !== availability.id
                )
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupClearGenerationError",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupClearGenerationError
              if (!studyGroup) return

              currentResults.studyGroup.sessionGenerationErrorMessage =
                studyGroup.sessionGenerationErrorMessage
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupResetLowBandwidthCloudroom",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } =
                response.studyGroupResetLowBandwidthCloudroom
              if (!studyGroup) return

              currentResults.studyGroup.lowBandwidthCloudroom =
                studyGroup.lowBandwidthCloudroom
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupResetAllowLongSessions",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupResetAllowLongSessions
              if (!studyGroup) return

              currentResults.studyGroup.allowLongSessions =
                studyGroup.allowLongSessions
              currentResults.studyGroup.allowLongSessionsSource =
                studyGroup.allowLongSessionsSource
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const { runMutation } = useMutation(buildMutation(updateMutation))
  const { runMutation: assignLessonPlan } = useMutation(
    buildMutation(assignLessonPlanMutation)
  )
  const { runMutation: clearGenerationError } = useMutation(
    buildMutation(clearErrorMutation)
  )
  const { runMutation: resetLowBandwidthCloudroom } = useMutation(
    buildMutation(resetLowBandwidthMutation)
  )
  const { runMutation: resetAllowLongSessions } = useMutation(
    buildMutation(resetAllowLongSessionsMutation)
  )
  const updateStudyGroup = params => {
    const studyGroup = loadingState.data.studyGroup
    const defaultParams = {
      id: studyGroup.id,
      organizationId: studyGroup.organization?.id,
      schoolId: studyGroup.school?.id,
      foreignLanguageId: studyGroup.foreignLanguage?.id,
      name: studyGroup.name,
      details: studyGroup.details,
      hourlyRate: studyGroup.hourlyRate,
      noShowRate: studyGroup.noShowRate,
      active: studyGroup.active,
      minimumStudentsThreshold: studyGroup.minimumStudentsThreshold,
      chimeCloudroomEnabled: studyGroup.chimeCloudroomEnabled,
      lowBandwidthCloudroom: studyGroup.lowBandwidthCloudroom,
      minTutorConsistencyRate: studyGroup.minTutorConsistencyRate,
      allowLongSessions: studyGroup.allowLongSessions,
      excusedAbsencesEnabled: studyGroup.excusedAbsencesEnabled,
      requestConfirmations: studyGroup.requestConfirmations,
      canExceedScheduledDuration: studyGroup.canExceedScheduledDuration,
      inSchool: studyGroup.inSchool,
      budgetId: studyGroup.budget?.id,
    }

    return runMutation({ input: { ...defaultParams, ...params } })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading the study group"
    >
      {({ data: { studyGroup, viewer } }) => (
        <>
          <NotificationContainer />
          {studyGroup.sessionGenerationErrorMessage && (
            <div className="alert alert-danger">
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="cursor-pointer"
                    onClick={() => clearGenerationError({ id })}
                  />
                </div>
                <div>
                  <div className="font-bold">
                    An error occurred while generating sessions:
                  </div>
                  <div
                    className="italic"
                    dangerouslySetInnerHTML={{
                      __html: studyGroup.sessionGenerationErrorMessage,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <button
            className="btn btn-warning"
            onClick={() => updateStudyGroup({ active: !studyGroup.active })}
          >
            {studyGroup.active ? "Archive Group" : "Un-Archive Group"}
          </button>
          <div className="row">
            <div className="col-12">
              <dl className="row">
                <>
                  <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                    Name
                  </dt>
                  <dd className="col-6 col-md-8 col-lg-9">
                    {studyGroup.oneOnOne ? (
                      studyGroup.name
                    ) : (
                      <EditableDisplay
                        displayValue={studyGroup.name}
                        save={({ value }) => updateStudyGroup({ name: value })}
                      />
                    )}
                  </dd>
                </>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  One on One?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  {studyGroup.oneOnOne ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success mr-3"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-danger mr-3"
                    />
                  )}
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Details
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: studyGroup.formattedDetails,
                        }}
                      />
                    }
                    editableValue={studyGroup.details ?? ""}
                    height="auto"
                    type="textarea"
                    save={({ value }) => updateStudyGroup({ details: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Hourly Rate
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.formattedHourlyRate !== null
                        ? studyGroup.formattedHourlyRate
                        : "Default"
                    }
                    save={({ value }) =>
                      updateStudyGroup({ hourlyRate: parseFloat(value) })
                    }
                    type="number"
                    step={0.01}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Organization
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.organization ? (
                        <a href={studyGroup.organization.showPath}>
                          {studyGroup.organization.name}
                        </a>
                      ) : (
                        "Public"
                      )
                    }
                    autocomplete
                    isClearable={false}
                    api="/api/admins/organizations/autocomplete_name"
                    save={({ selectedId }) =>
                      updateStudyGroup({ organizationId: selectedId })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  School
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.school ? (
                        <a href={studyGroup.school.showPath}>
                          {studyGroup.school.name}
                        </a>
                      ) : (
                        "No School Set"
                      )
                    }
                    autocomplete
                    isClearable={false}
                    api="/api/admins/schools/autocomplete_name"
                    searchOptions={`organization_id=${studyGroup.organization?.id}`}
                    save={({ selectedId }) =>
                      updateStudyGroup({ schoolId: selectedId })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Foreign Language Requirement
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.foreignLanguage ? (
                        <a href={studyGroup.foreignLanguage.showPath}>
                          {studyGroup.foreignLanguage.name}
                        </a>
                      ) : (
                        "No Language Requirement"
                      )
                    }
                    autocomplete
                    isClearable={false}
                    api="/api/admins/foreign_languages/autocomplete_name"
                    save={({ selectedId }) =>
                      updateStudyGroup({ foreignLanguageId: selectedId })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Use Chime Cloudrooms?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.chimeCloudroomEnabled ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={studyGroup.chimeCloudroomEnabled}
                    type="checkbox"
                    save={({ value }) =>
                      updateStudyGroup({ chimeCloudroomEnabled: value })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Low Bandwidth Cloudroom
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplayWithReset
                    value={studyGroup.lowBandwidthCloudroom}
                    reset={() => resetLowBandwidthCloudroom({ id })}
                    update={({ value }) =>
                      updateStudyGroup({ lowBandwidthCloudroom: value })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Allow Sessions Longer than 1 Hour?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  {studyGroup.organization ? (
                    <EditableDisplayWithReset
                      value={studyGroup.allowLongSessions}
                      reset={() => resetAllowLongSessions({ id })}
                      update={({ value }) =>
                        updateStudyGroup({ allowLongSessions: value })
                      }
                      source={studyGroup.allowLongSessionsSource.replaceAll(
                        "_",
                        " "
                      )}
                    />
                  ) : (
                    <EditableDisplay
                      displayValue={
                        studyGroup.allowLongSessions ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-danger"
                          />
                        )
                      }
                      editableValue={!!studyGroup.allowLongSessions}
                      type="checkbox"
                      save={({ value }) =>
                        updateStudyGroup({ allowLongSessions: value })
                      }
                    />
                  )}
                </dd>

                {!studyGroup.oneOnOne && (
                  <>
                    <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                      Required Tutor Consistency Rate
                    </dt>
                    <dd className="col-6 col-md-8 col-lg-9">
                      <EditableDisplay
                        displayValue={
                          studyGroup.minTutorConsistencyRate
                            ? `${studyGroup.minTutorConsistencyRate}%`
                            : "None"
                        }
                        editableValue={studyGroup.minTutorConsistencyRate}
                        save={({ value }) =>
                          updateStudyGroup({
                            minTutorConsistencyRate: parseInt(value),
                          })
                        }
                        type="number"
                        step={1}
                      />
                      <span className="italic">
                        Setting this to 0 will remove the consistency
                        requirement altogether.
                      </span>
                    </dd>
                  </>
                )}

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Excused Absences Enabled?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.excusedAbsencesEnabled ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={studyGroup.excusedAbsencesEnabled}
                    type="checkbox"
                    save={({ value }) =>
                      updateStudyGroup({ excusedAbsencesEnabled: value })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Request Student Confirmations?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.requestConfirmations ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={studyGroup.requestConfirmations}
                    type="checkbox"
                    save={({ value }) =>
                      updateStudyGroup({ requestConfirmations: value })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Tutors can bill for longer than scheduled duration?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.canExceedScheduledDuration ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={studyGroup.canExceedScheduledDuration}
                    type="checkbox"
                    save={({ value }) =>
                      updateStudyGroup({ canExceedScheduledDuration: value })
                    }
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  In-School Program?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      studyGroup.inSchool ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={studyGroup.inSchool}
                    type="checkbox"
                    save={({ value }) => updateStudyGroup({ inSchool: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Split Bill Enabled?
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  {studyGroup.splitBill ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success mr-3"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-danger mr-3"
                    />
                  )}
                </dd>

                {studyGroup.splitBill && (
                  <>
                    <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                      Minimum Students Threshold
                    </dt>
                    <dd className="col-6 col-md-8 col-lg-9">
                      <EditableDisplay
                        displayValue={studyGroup.minimumStudentsThreshold}
                        type="number"
                        save={({ value }) =>
                          updateStudyGroup({
                            minimumStudentsThreshold: parseInt(value),
                          })
                        }
                      />
                    </dd>

                    <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                      No Show Rate
                    </dt>
                    <dd className="col-6 col-md-8 col-lg-9">
                      <EditableDisplay
                        displayValue={
                          studyGroup.formattedNoShowRate !== null
                            ? studyGroup.formattedNoShowRate
                            : "Default"
                        }
                        save={({ value }) =>
                          updateStudyGroup({ noShowRate: parseFloat(value) })
                        }
                        type="number"
                      />
                    </dd>
                  </>
                )}

                {viewer?.canAdminBudgets && (
                  <>
                    <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                      Budget
                    </dt>
                    <dd className="col-6 col-md-8 col-lg-9">
                      <EditableDisplay
                        displayValue={
                          studyGroup.budget ? (
                            <a href={studyGroup.budget.showPath}>
                              {studyGroup.budget.name}
                            </a>
                          ) : (
                            "None"
                          )
                        }
                        autocomplete
                        isClearable={false}
                        api="/api/admins/budgets/autocomplete_name"
                        searchOptions={`organization_id=${studyGroup.organization?.id}`}
                        save={({ selectedId }) =>
                          updateStudyGroup({ budgetId: selectedId })
                        }
                      />
                    </dd>
                  </>
                )}

                <dt className="col-6 col-md-4 col-lg-3 d-flex items-center">
                  Tags
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <StudyGroupTags studyGroupId={studyGroup.id} />
                </dd>
              </dl>
            </div>

            {studyGroup.studyGroupGeneration ? (
              <GenerationDetails studyGroup={studyGroup} />
            ) : (
              <Availabilities studyGroup={studyGroup} />
            )}

            {studyGroup.course && <CourseDetails studyGroup={studyGroup} />}

            <div className="col-12 col-md-6 col-xl-4">
              {studyGroup.oneOnOne ? (
                <div className="mb-4">
                  <h4 className="mt-0">Student</h4>
                  <a
                    className="flex-grow-1 mb-4"
                    href={studyGroup.students[0].showPath}
                  >
                    {studyGroup.students[0].fullName}
                  </a>
                </div>
              ) : (
                <Students
                  students={studyGroup.students}
                  studyGroupId={id}
                  organizationId={studyGroup.organization?.id}
                  errorMessages={studentErrorMessages}
                  requestConfirmations={studyGroup.requestConfirmations}
                />
              )}
            </div>

            {!studyGroup.oneOnOne && (
              <div className="col-12 col-md-6 col-xl-4">
                <Teachers
                  teachers={studyGroup.teachers}
                  studyGroupId={id}
                  organizationId={studyGroup.organization?.id}
                />
              </div>
            )}

            <div className="col-12 col-md-6 col-xl-4">
              {studyGroup.oneOnOne ? (
                <div className="mb-4">
                  <h4 className="mt-0">Tutor</h4>
                  <a className="flex-grow-1" href={studyGroup.tutor.showPath}>
                    {studyGroup.tutor.fullName}
                  </a>
                </div>
              ) : (
                <>
                  <Tutor
                    tutor={studyGroup.tutor}
                    studyGroupId={id}
                    organizationId={studyGroup.organization?.id}
                  />
                  {studyGroup.openStudyGroupTutorReplacements.length ? (
                    <div>
                      <h4>Open Replacement Requests</h4>
                      <EditableList>
                        {ordered(
                          studyGroup.openStudyGroupTutorReplacements,
                          "startsAt"
                        ).map(replacement => (
                          <motion.li key={replacement.id}>
                            <Item
                              mutation={removeReplacementMutation}
                              mutationProps={{
                                id: replacement.id,
                              }}
                            >
                              Starts{" "}
                              <LocalTime
                                timestamp={replacement.startsAt}
                                omitTime
                              />
                            </Item>
                          </motion.li>
                        ))}
                      </EditableList>
                    </div>
                  ) : null}
                  {studyGroup.futureStudyGroupTutorReplacements.length ? (
                    <div>
                      <h4>Future Tutor</h4>
                      <EditableList>
                        {ordered(
                          studyGroup.futureStudyGroupTutorReplacements,
                          "startsAt"
                        ).map(replacement => (
                          <motion.li key={replacement.id}>
                            <Item
                              mutation={removeReplacementMutation}
                              mutationProps={{
                                id: replacement.id,
                              }}
                            >
                              <a href={replacement.newTutor.showPath}>
                                {replacement.newTutor.fullName}: Starts{" "}
                                <LocalTime timestamp={replacement.startsAt} />
                              </a>
                            </Item>
                          </motion.li>
                        ))}
                      </EditableList>
                    </div>
                  ) : null}
                  <CreateStudyGroupTutorReplacement studyGroupId={id} />
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-xl-4">
              <Subjects subjects={studyGroup.subjects} studyGroupId={id} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h4>Lesson Plans</h4>
              <ul className="list-unstyled">
                {ordered(studyGroup.lessonPlans, "name").map(lessonPlan => (
                  <li key={lessonPlan.id}>
                    <a href={lessonPlan.showPath}>{lessonPlan.name}</a>
                  </li>
                ))}
              </ul>
              <AutocompleteSelect
                ref={planSelectRef}
                api="/api/admins/lesson_plans/autocomplete_name"
                placeholder="Assign Lesson Plan"
                onChange={selected => {
                  if (!selected?.value) return
                  assignLessonPlan({
                    studyGroupId: id,
                    lessonPlanId: selected.value,
                  }).then(r => planSelectRef.current.clearValue())
                }}
              />
            </div>

            <div className="col-12">
              <AddTailoredAssignment studyGroupId={id} />
            </div>
          </div>

          <div className="d-flex align-items-center mb-4">
            <h2 className="d-inline-block m-0 mr-5">Sessions</h2>
            <a className="btn btn-success" href={scheduleSessionPath}>
              Schedule Session
            </a>
            <DeleteFutureSessions studyGroupId={id} />
          </div>
          <div className="row">
            {studyGroup.airtutorsSessions.data.map(session => (
              <SessionDetails
                key={session.id}
                session={session}
                studyGroup={studyGroup}
              />
            ))}
            <Pagination
              totalPages={studyGroup.airtutorsSessions.totalPages}
              currentPage={studyGroup.airtutorsSessions.currentPage}
              changePage={page => setPage(page)}
            />
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const studyGroupQuery = compress`
  query($id: ID!, $page: Int) {
    viewer {
      canAdminBudgets
    }
    studyGroup(id: $id) {
      id
      name
      active
      oneOnOne
      details
      formattedDetails
      hourlyRate
      formattedHourlyRate
      formattedNoShowRate
      splitBill
      minimumStudentsThreshold
      minTutorConsistencyRate
      excusedAbsencesEnabled
      requestConfirmations
      canExceedScheduledDuration
      inSchool
      sessionGenerationErrorMessage
      chimeCloudroomEnabled
      lowBandwidthCloudroom
      minTutorConsistencyRate
      allowLongSessions
      allowLongSessionsSource
      minTutorConsistencyRate
      organization {
        id
        name
        showPath
      }
      budget {
        id
        name
        showPath
      }
      school {
        id
        name
        showPath
      }
      foreignLanguage {
        id
        name
        showPath
      }
      students {
        id
        fullName
        lastName
        showPath
        lessonPlans {
          id
          name
        }
        studyGroupUser(studyGroupId: $id) {
          id
          confirmedAt
        }
      }
      teachers {
        id
        fullName
        lastName
        showPath
      }
      tutor {
        id
        fullName
        lastName
        showPath
      }
      futureStudyGroupTutorReplacements {
        id
        startsAt
        newTutor {
          id
          fullName
          lastName
          showPath
        }
      }
      openStudyGroupTutorReplacements {
        id
        startsAt
      }
      subjects {
        id
        name
      }
      lessonPlans {
        id
        name
        showPath
      }
      teacherSubject {
        teacher {
          fullName
          lastName
        }
        subject {
          name
        }
      }
      availabilities {
        id
        startsAt
        endsAt
        weekday
        weekdayNumber
      }
      studyGroupGeneration {
        minimumGroupSize
        maximumGroupSize
        showPath
      }
      course {
        id
        showPath
        courseCluster {
          name
        }
        tailoredAssignments {
          id
          name
          showPath
        }
      }
      courseSection {
        id
        startsOn
        endsOn
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
      }
      airtutorsSessions(page: $page) {
        totalPages
        currentPage
        data {
          id
          startsAt
          endsAt
          noShow
          statusState
          billState
          durationChangeState
          showPath
          subject {
            id
            name
          }
          tutor {
            id
            fullName
            lastName
            showPath
          }
          activeRequestedTutors {
            id
            fullName
            lastName
            showPath
          }
          students {
            id
            fullName
            lastName
            showPath
          }
        }
      }
    }
  }
`

const updateMutation = compress`
  mutation(
    $input: StudyGroupInputObject!
  ) {
    studyGroupUpdate(
      input: $input
    ) {
      studyGroup {
        name
        active
        minimumStudentsThreshold
        details
        formattedDetails
        formattedHourlyRate
        hourlyRate
        formattedNoShowRate
        lowBandwidthCloudroom
        minTutorConsistencyRate
        allowLongSessions
        allowLongSessionsSource
        minTutorConsistencyRate
        excusedAbsencesEnabled
        chimeCloudroomEnabled
        requestConfirmations
        canExceedScheduledDuration
        inSchool
        organization {
          id
          name
          showPath
        }
        budget {
          id
          name
          showPath
        }
        school {
          id
          name
          showPath
        }
        foreignLanguage {
          id
          name
          showPath
        }
      }
    }
  }
`

const assignLessonPlanMutation = compress`
  mutation($studyGroupId: ID!, $lessonPlanId: ID!) {
    assignLessonPlan(studyGroupId: $studyGroupId, lessonPlanId: $lessonPlanId) {
      studyGroup {
        lessonPlans {
          id
          name
          showPath
        }
      }
    }
  }
`

const clearErrorMutation = compress`
  mutation($id: ID!) {
    studyGroupClearGenerationError(studyGroupId: $id) {
      errorMessages
      studyGroup {
        sessionGenerationErrorMessage
      }
    }
  }
`

const resetLowBandwidthMutation = compress`
  mutation($id: ID!) {
    studyGroupResetLowBandwidthCloudroom(studyGroupId: $id) {
      errorMessages
      studyGroup {
        lowBandwidthCloudroom
      }
    }
  }
`

const resetAllowLongSessionsMutation = compress`
  mutation($id: ID!) {
    studyGroupResetAllowLongSessions(studyGroupId: $id) {
      failures {
        message
      }
      studyGroup {
        allowLongSessions
        allowLongSessionsSource
      }
    }
  }
`

const removeReplacementMutation = compress`
  mutation($id: ID!) {
    removeStudyGroupTutorReplacement(studyGroupTutorReplacementId: $id) {
      failures {
        message
      }
      studyGroup {
        id
        openStudyGroupTutorReplacements {
          id
          startsAt
        }
        futureStudyGroupTutorReplacements {
          id
          startsAt
          newTutor {
            id
            fullName
            lastName
            showPath
          }
        }
      }
    }
  }
`

StudyGroupDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default StudyGroupDetails
