import React from "react"

import { Form, Formik } from "formik"

import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

import TailoredQuestion from "./TailoredQuestion"

const TailoredSection = ({ tailoredSectionId, studentId }) => {
  const client = useClient()
  const [result] = useQuery({
    query,
    variables: { tailoredSectionId, studentId },
    client,
  })

  const buildFormState = section => {
    const studentAnswers = {}
    section.tailoredStudentAnswers.forEach(studentAnswer => {
      studentAnswers[studentAnswer.tailoredQuestion.id] = {
        answerId: studentAnswer.tailoredAnswer.id,
      }
    })

    return { studentAnswers }
  }

  const [, runMutation] = useMutation(submitTailoredSectionMutation, client)

  const handleSubmit = (values, actions) => {
    const params = {
      tailoredSectionId,
      studentAnswers: [],
    }

    Object.keys(values.studentAnswers).forEach(id => {
      params.studentAnswers.push({
        tailoredQuestionId: id,
        tailoredAnswerId: values.studentAnswers[id].answerId,
      })
    })

    runMutation(params)
      .then(
        result => {
          const { failures, redirectTo } = result.data.submitTailoredSection
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            window.location.href = redirectTo
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { section } }) => (
        <>
          <h3>{section.name}</h3>
          <Formik
            initialValues={buildFormState(section)}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            <Form>
              <React.Fragment>
                {section.tailoredQuestions.map(question => (
                  <TailoredQuestion key={question.id} question={question} />
                ))}

                {section.tailoredQuestionGroups.map(
                  tailoredQuestionGroup =>
                    tailoredQuestionGroup.tailoredQuestions.length > 0 && (
                      <div key={tailoredQuestionGroup.id}>
                        <div id={`group-${tailoredQuestionGroup.id}`}>
                          <p className="text-xl font-semibold">
                            The next{" "}
                            {tailoredQuestionGroup.tailoredQuestions.length}{" "}
                            questions will reference the following image(s).
                          </p>
                          <div className="flex space-x-4">
                            {tailoredQuestionGroup.diagrams.map(diagram => (
                              <img
                                className="h-80"
                                key={diagram.url}
                                src={diagram.url}
                                alt={diagram.filename}
                              />
                            ))}
                          </div>
                        </div>

                        {tailoredQuestionGroup.tailoredQuestions.map(
                          tailoredQuestion => (
                            <TailoredQuestion
                              key={tailoredQuestion.id}
                              question={tailoredQuestion}
                              scrollTo={`group-${tailoredQuestionGroup.id}`}
                            />
                          )
                        )}
                      </div>
                    )
                )}

                <FormFailures />

                <div className="flex space-x-4">
                  {section.previousSection && (
                    <a
                      href={section.previousSection.completePath}
                      className="btn solid orange"
                    >
                      Go back to {section.previousSection.name}
                    </a>
                  )}

                  <SubmitButton>
                    {section.nextSection
                      ? `Next: ${section.nextSection.name}`
                      : "Complete Assignment"}
                  </SubmitButton>
                </div>
              </React.Fragment>
            </Form>
          </Formik>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query TailoredSectionQuery($tailoredSectionId: ID!, $studentId: ID!) {
    section: tailoredSection(id: $tailoredSectionId) {
      id
      name
      previousSection {
        id
        name
        completePath
      }
      nextSection {
        id
        name
      }
      tailoredStudentAnswers(studentId: $studentId) {
        id
        tailoredQuestion {
          id
        }
        tailoredAnswer {
          id
        }
      }
      tailoredQuestionGroups {
        id
        diagrams {
          filename
          url
        }
        tailoredQuestions {
          id
          type
          text
          image {
            filename
            url
          }
          tailoredAnswers {
            id
            type
            text
            correct
            image {
              filename
              url
            }
          }
        }
      }
      tailoredQuestions {
        id
        type
        text
        image {
          filename
          url
        }
        diagram {
          filename
          url
        }
        tailoredAnswers {
          id
          type
          text
          correct
          image {
            filename
            url
          }
        }
      }
    }
  }
`

const submitTailoredSectionMutation = gql`
  mutation submitTailoredSection(
    $tailoredSectionId: ID!
    $studentAnswers: [TailoredStudentAnswerInputObject!]!
  ) {
    submitTailoredSection(
      tailoredSectionId: $tailoredSectionId
      studentAnswers: $studentAnswers
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default TailoredSection
