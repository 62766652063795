import React from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ArrowUpIcon } from "@heroicons/react/20/solid"

import classNames from "src/classNames"

export type TailoredQuestionProps = {
  question: any
  studentAnswer: any
  scrollTo?: string
}

const TailoredQuestion: React.FC<TailoredQuestionProps> = ({
  question,
  studentAnswer,
  scrollTo,
}) => {
  return (
    <div
      key={question.id}
      className={classNames(
        "my-4 rounded-md border-solid p-4 font-mono",
        studentAnswer?.correct ? "border-emerald-600" : "border-red-600"
      )}
    >
      <h4>
        {studentAnswer?.correct ? (
          <FontAwesomeIcon icon={faCheck} className="text-emerald-600" />
        ) : (
          <FontAwesomeIcon icon={faTimes} className="text-red-400" />
        )}{" "}
        {question.type === "text" ? (
          question.text
        ) : (
          <img
            src={question.image.url}
            alt={question.image.filename || "question"}
          />
        )}
      </h4>
      {scrollTo && (
        <span
          onClick={() => document.getElementById(scrollTo)?.scrollIntoView()}
          className="flex items-center font-sans text-gray-500 hover:cursor-pointer"
        >
          Jump to Reference Image(s) <ArrowUpIcon className="ml-2 h-5 w-5" />
        </span>
      )}
      <hr className="my-4 h-0.5" />
      <ul className="pl-0">
        {question.tailoredAnswers.map(answer => (
          <div key={answer.id} className="form-group">
            <div className="form-check">
              <div
                className={classNames(
                  "py-2 px-3",
                  answer.correct && "max-w-max rounded-md bg-emerald-100"
                )}
              >
                <label
                  className={classNames(
                    "form-check-label flex items-center hover:cursor-default"
                  )}
                >
                  <input
                    type="radio"
                    disabled
                    className="form-check-input"
                    checked={answer.id === studentAnswer?.tailoredAnswer.id}
                  />
                  {answer.type === "text" ? (
                    answer.text
                  ) : (
                    <img
                      src={answer.image.url}
                      alt={answer.image.filename || "answer"}
                    />
                  )}
                  <span className="form-check-sign" />
                </label>
              </div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  )
}

export default TailoredQuestion
